@import "@fontsource/poppins";

* {
  font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 1px;
  font-weight: 400;
}

a {
  text-decoration: none;
}

.content {
  margin-top: 60px;
}

.content ul {
  list-style: none;
  padding-inline-start: 0;
}
.content li {
  margin-left: 1em;
}


.content li::before {
  content: "";
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  margin-right: 0.5em;
  background-color: #ee312f;

}

.gecko-image{
  position: absolute;
  bottom: 0;
  left: 0;
}

.sticky-button {
  text-align: center;
  font-weight: bold;
  font-size: larger;
  padding: 10px;
  height: 60px;
  background-color: #ee312f;
  color: #ffffff;
  outline: none;
  position: sticky;
  bottom: 0px;
  left: 0px;
  display: block;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.nav-item .nav-link::after {
  content: "";
  display: block;
  height: 2px;
  background-color: #000025;
  margin-top: 3px;
}

.nav-item:hover .nav-link::after {
  content: "";
  display: block;
  height: 2px;
  background-color: #ee312f;
  margin-top: 3px;
}

.sep {
  width: 100px;
  height: 2px;
  background-color: #ee312f;
  margin-bottom: 20px;
}

.Footer {
  margin-top: auto;
  padding-top: 30px;
}

.slick-prev:before {
  content: ''!important;
}
.slick-next:before {
  content: ''!important;
}

.feedbackSlider {
  margin-left: 25px;
  margin-right: 25px;
}
.slick-arrow {
  z-index: 500;
}





/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/